module.exports = {
	siteMetadata: {
		siteUrl: "https://strategy.embarkagency.com.au",
		title: "Website Strategy",
	},
	plugins: [{
			resolve: "gatsby-source-wordpress",
			options: {
				url: "https://strategy.embarkagency.com.au/wp/graphql",
			},
		},
		"gatsby-plugin-styled-components",
		"gatsby-plugin-image",
		{
			resolve: "gatsby-plugin-manifest",
			options: {
				icon: "src/images/icon.png",
				name: `Embark Web Strategy`,
				short_name: `Web Strategy`,
				start_url: `/`,
				background_color: `#fff`,
				theme_color: `#b15fe9`,
				display: `standalone`,
			},
		},
		"gatsby-plugin-sharp",
		"gatsby-transformer-sharp",
		{
			resolve: "gatsby-source-filesystem",
			options: {
				name: "images",
				path: "./src/images/",
			},
			__key: "images",
		},
		{
			resolve: "@chakra-ui/gatsby-plugin",
			options: {
				resetCSS: true
			}
		},
		{
			resolve: 'gatsby-plugin-load-script',
			options: {
				src: 'https://kit.fontawesome.com/1c67a45d84.js',
				crossorigin: 'anonymous',
			},
		},
		{
			resolve: 'gatsby-plugin-react-svg',
			options: {
			  rule: {
				include: /images\/illustrations\/.*\.svg/
			  }
			}
		},
		"gatsby-transformer-inline-svg"
	],
};