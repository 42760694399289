import fetch from 'cross-fetch';

import config from "../../gatsby-config";
const gqlPlugin = config.plugins.filter(p => p.resolve === "gatsby-source-wordpress")[0];

import {
    ApolloClient,
    createHttpLink,
    InMemoryCache
} from '@apollo/client';
import {
    setContext
} from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: gqlPlugin.options.url,
    fetch
});

const authLink = setContext((_, {
    headers
}) => {
    if(_.variables && _.variables.bypass === true) {
        return {};
    }
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('user-token');
    if(!token) {
        return {};
    }
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",        
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});