import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  "colors": {
    "blue": {
      "50": "#f5f7ff",
      "100": "#B8C6FF",
      "200": "#8AA2FF",
      "300": "#5C7DFF",
      "400": "#2E59FF",
      "500": "#4067FF",
      "600": "#002ACC",
      "700": "#001F99",
      "800": "#001566",
      "900": "#000A33"
    },
    "purple": {
      "50": "#F4E9FC",
      "100": "#E1C1F6",
      "200": "#CD98F0",
      "300": "#B970EA",
      "400": "#A648E5",
      "500": "#b15fe9",
      "600": "#751AB2",
      "700": "#581386",
      "800": "#3B0D59",
      "900": "#1D062D"
    },
    "yellow": {
      "50": "#FFF7E6",
      "100": "#FFE9B8",
      "200": "#FEDB8A",
      "300": "#FECD5D",
      "400": "#FEBF2F",
      "500": "#fec748",
      "600": "#CB8E01",
      "700": "#986A01",
      "800": "#654701",
      "900": "#332300"
    },
    "green": {
      "50": "#EBFAEB",
      "100": "#C6F1C8",
      "200": "#A1E8A5",
      "300": "#7CDE81",
      "400": "#58D55E",
      "500": "#33CC3A",
      "600": "#29A32F",
      "700": "#1F7A23",
      "800": "#145217",
      "900": "#0A290C"
    },
    "teal": {
      "50": "#E9FBF7",
      "100": "#C2F5E8",
      "200": "#9BEED9",
      "300": "#74E7CA",
      "400": "#4DE0BB",
      "500": "#4be0ba",
      "600": "#1EAE89",
      "700": "#168367",
      "800": "#0F5745",
      "900": "#072C22"
    },
    "pink": {
      "50": "#FFE5EE",
      "100": "#FFB8CE",
      "200": "#FF8AAF",
      "300": "#FF5C8F",
      "400": "#FF2E70",
      "500": "#ff2b6e",
      "600": "#CC0040",
      "700": "#990030",
      "800": "#660020",
      "900": "#330010"
    },
    "cyan": {
      "50": "#E5FCFF",
      "100": "#B8F6FF",
      "200": "#8AF0FF",
      "300": "#5CEBFF",
      "400": "#2EE5FF",
      "500": "#00DFFF",
      "600": "#00B2CC",
      "700": "#008699",
      "800": "#005966",
      "900": "#002D33"
    }
  }
});

export default theme;